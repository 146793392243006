import React from "react";
import { getManu } from "../../redux/actions";
import { connect } from "react-redux";
// import {footerMenu} from "../../data";
import { Container } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFacebook, faTwitter, faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import CopyRight from "../../components/CopyRight";
//import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const Footer = () => {
	//const { t } = useTranslation();
	const user = useSelector(({ user }) => user);
	const handleShow = () => {
		if (window.Optanon) {
			window.Optanon.ToggleInfoDisplay();
		}
	};
	if (user.accessToken) {
		return (
			<>
				<footer>
					<Container fluid className="footer">
						<div className="row justify-content-center">
							<div className="col-sm-12">
								<p className="mt-3 mb-3 text-center">
									{/* For additional information Call 1-800-332-778 | Call center hours 24/7, 365 days a year. */}
									For questions related to the PGP Rewards program, please contact your PGP Representative. For technical questions or issues, please contact{" "}
									<a className="text-link" href="/support">
										support@receiptproccesor.com
									</a>
								</p>
								{/* show after login only */}
								<div>
									<div className="footer-link" aria-label="Footer Navigation">
										<ul>
											<li>
												<a href="https://pgpro.com/en-us/about-us" target="_blank">
													About Us
												</a>
											</li>
											<li>|</li>
											<li>
												<a href="https://pgpro.com/en-us/contact-us" target="_blank">
													Contact Us
												</a>
											</li>
											<li>|</li>
											<li>
												<a href="https://privacypolicy.pg.com/en/#statePrivacyNotice" target="_blank">
													Privacy
												</a>
											</li>
											<li>|</li>
											{/* <li>
											<a href="https://privacypolicy.pg.com/en/#CCPA" target="_blank">
												CA Privacy
											</a>
										</li>
										<li>|</li> */}
											<li>
												<a href="https://termsandconditions.pg.com/en-us/" target="_blank">
													Terms &amp; Conditions
												</a>
											</li>
											<li>|</li>
											<li>
												<a href="/termsandconditions">Program Terms & Conditions</a>
											</li>
											<li>|</li>
											<li>
												<button onClick={handleShow} className="btn poplink">
													Do Not Sell My Personal Information
												</button>
											</li>
											<li>|</li>
											<li>
												<a href="https://preferencecenter.pg.com/en-us" target="_blank">
													Your Privacy Choices{" "}
													<span className="ml-1">
														<img src="/public/assets/images/privacyoptions123x59.png" alt="Your Privacy Option" width="40px" />
													</span>
												</a>
											</li>
											{/* <li>|</li>
										<li>
											<a href="http://www.pg.com/privacy/english/privacy_statement.shtml#iba" target="_blank" >
												AdChoices<img src="/public/assets/images/AdChoicesicon.png" alt="Adchoices logo" className="adchoices" />
											</a>
										</li> */}
										</ul>
									</div>

									<div className="my-3 text-center">
										<p>
											This P&G Program scope concerns qualifying U.S. sales made by P&G to participating private companies of certain P&G products outlined in the listed promotions (which are strictly limited to P&G Fabric, Home and Family Care products). P&G Program participants agree that they will not claim or offer incentives for sales in violation of this P&G Program
											scope nor offer, share, pay, or otherwise provide any portion of incentives earned under the P&G Program to others in order to induce the purchase of P&G products. Participation in the P&G Program does not create an employment or agency relationship with P&G and requires the prior approval of a participant’s employer. Please see{" "}
											<a href="/termsandconditions" target="_blank">
												<u>P&G Professional Partner Rewards Terms & Conditions</u>
											</a>{" "}
											for full details.
										</p>
									</div>
								</div>
							</div>
						</div>
					</Container>
					<CopyRight />
					<div id="onetrust-consent-sdk" />
				</footer>
			</>
		);
	} else {
		return (
			<>
				<footer>
					<Container fluid className="footer">
						<div className="row justify-content-center">
							<div className="col-sm-12">
								<p className="mt-3 mb-3 text-center">
									{/* For additional information Call 1-800-332-778 | Call center hours 24/7, 365 days a year. */}
									For questions related to the PGP Rewards program, please contact your PGP Representative. For technical questions or issues, please contact{" "}
									<a className="text-link" href="/support">
										support@receiptproccesor.com
									</a>
								</p>
								{/* for non log in user */}
								<div>
									<div className="footer-link">
										<ul>
											<li>
												<a href="https://pgpro.com/en-us/about-us" target="_blank">
													About Us
												</a>
											</li>
											<li>|</li>
											<li>
												<a href="https://pgpro.com/en-us/contact-us" target="_blank">
													Contact Us
												</a>
											</li>
											<li>|</li>
											<li>
												<a href="https://privacypolicy.pg.com/en/#statePrivacyNotice" target="_blank">
													Privacy
												</a>
											</li>
											{/* <li>|</li>
										<li>
											<a href="https://privacypolicy.pg.com/en/#CCPA" target="_blank">
												CA Privacy
											</a>
										</li> */}
											<li>|</li>
											<li>
												<a href="https://termsandconditions.pg.com/en-us/" target="_blank">
													Terms &amp; Conditions
												</a>
											</li>
											<li>|</li>
											<li>
												<button onClick={handleShow} className="btn poplink">
													Do Not Sell My Personal Information
												</button>
											</li>
											<li>|</li>
											<li>
												<a href="https://preferencecenter.pg.com/en-us" target="_blank">
													Your Privacy Choices{" "}
													<span className="ml-1">
														<img src="/public/assets/images/privacyoptions123x59.png" alt="Your Privacy Option" width="40px" />
													</span>
												</a>
											</li>

											{/* <li>|</li>
										<li>
											<a href="http://www.pg.com/privacy/english/privacy_statement.shtml#iba" target="_blank" >
												AdChoices<img src="/public/assets/images/AdChoicesicon.png" alt="Adchoices logo" className="adchoices" />
											</a>
										</li> */}
										</ul>
									</div>

									<p className="my-3 text-center">P&G Professional leverages P&G’s scale, trusted brands, and strengths in market and consumer understanding to meet the specific business needs in a wide range of industries, such as foodservice, healthcare, hospitality, janitorial services, cleaning and maintenance, education, and care homes.</p>
								</div>
							</div>
						</div>
					</Container>
					<CopyRight />
					<div id="onetrust-consent-sdk" />
				</footer>
			</>
		);
	}
};
export default connect(null, { getManu })(Footer);
